import React, { useState } from 'react';

const DisplayContext = React.createContext({ mode: 'screen' });

export default function DisplayProvider({ children }) {
  const [mode, setMode] = useState('screen');

  return <DisplayContext.Provider value={{ mode, setMode }}>{children}</DisplayContext.Provider>;
}

export const useDisplay = () => React.useContext(DisplayContext);
