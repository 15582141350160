const BASE_DAYS = [
  {
    itemIndex: 0,
    position: 6,
  },
  {
    itemIndex: 1,
    position: 0,
  },
  {
    itemIndex: 2,
    position: 1,
  },
  {
    itemIndex: 3,
    position: 2,
  },
  {
    itemIndex: 4,
    position: 3,
  },
  {
    itemIndex: 5,
    position: 4,
  },
  {
    itemIndex: 6,
    position: 5,
  },
];

export const DAYS = BASE_DAYS.map((day) => ({
  ...day,
  translationKey: `timePeriods.days.${day.itemIndex}.long`,
}));

export const DAYS_ABBREVIATED = DAYS.map((day) => ({
  ...day,
  translationKey: `timePeriods.days.${day.itemIndex}.abbreviated`,
}));

export const MONTHS = [...Array(12).keys()].map((itemIndex) => ({
  itemIndex,
  translationKey: `timePeriods.months.${itemIndex}.long`,
}));

export const MONTHS_ABBREVIATED = [...Array(12).keys()].map((itemIndex) => ({
  itemIndex,
  translationKey: `timePeriods.months.${itemIndex}.abbreviated`,
}));
