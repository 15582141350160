import { IntlProvider } from 'react-intl';
import { messages } from './reactIntlConfig';

export default function ReactIntlProvider({ children, defaultLocale }) {
  const lng = 'en';
  const currentMessages =
    messages[lng] || messages[defaultLocale] || messages[Object.keys(messages)[0]];

  return (
    <IntlProvider locale={lng} key={lng} messages={currentMessages}>
      {children}
    </IntlProvider>
  );
}
