import translations from '../config/translations';

// From https://formatjs.io/docs/react-intl/upgrade-guide-2x/#flatten-messages-object
function flattenMessages(nestedMessages, prefix = '') {
  return Object.keys(nestedMessages).reduce((messages, key) => {
    const value = nestedMessages[key];
    const prefixedKey = prefix ? `${prefix}.${key}` : key;

    if (typeof value === 'string') {
      messages[prefixedKey] = value; // eslint-disable-line
    } else if (value === null) {
      // react-intl will not allow empty strings so we have to put a space for translations set to null
      // https://github.com/formatjs/formatjs/issues/607
      messages[prefixedKey] = ' '; // eslint-disable-line
    } else {
      Object.assign(messages, flattenMessages(value, prefixedKey));
    }

    return messages;
  }, {});
}

const languages = Object.keys(translations).reduce((languageDict, key) => {
  languageDict[key] = translations[key].translation[key] || key; // eslint-disable-line
  return languageDict;
}, {});

const messages = Object.keys(translations).reduce((messages, key) => {
  messages[key] = flattenMessages(translations[key].translation); // eslint-disable-line
  return messages;
}, {});

export { messages, languages };
